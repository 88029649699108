import React, { FC } from 'react';
import {
  GoogleMap,
  LoadScript,
  Marker,
  Polyline,
  // InfoWindow,
} from '@react-google-maps/api';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { MapRoutesProps } from './mapRoutes.interface';
import { Loader } from '../Loader';
import { MapContainer } from './MapRoutes.styles';
import { Context } from '../../context';

export const MapRoutes: FC<MapRoutesProps> = (props) => {
  const {
    setRegionRoutes,
    setLevel,
    setLocation,
    timeFilter,
    setLiveDetailsState,
    setAlert,
    setAlertText,
    gotoLiveCasesLocation,
    getTime,
    setVehicleInstruction,
    setNumOfJourneysByFacility,
    tokenStorage,
    getAccessToken,
    redirectToLandingOnError,
    // defaultCenter
  } = React.useContext(Context);

  const accessToken: string = getAccessToken(tokenStorage);

  const config = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  };

  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(true);
  const [mapData, setMapData] = React.useState([] as any);
  const [mapDataFinal, setMapDataFinal] = React.useState([] as any);
  const [lines, setLines] = React.useState([] as any);
  // const [centerLocation, setCenterLocation] = React.useState(defaultCenter);
  const [googleMap, setGoogleMap] = React.useState(null as any);
  const [zooming, setZooming] = React.useState(0);
  const [multicolorRoute, setMulticolorRoute] = React.useState([] as any);

  const [vehiclesMarker, setVehiclesMarker] = React.useState([] as any);
  const [vehicleTypes, setvehicleTypes] = React.useState([] as any);

  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '';

  const { match } = props;

  const mapStyles = {
    width: '100%',
    height: '100%',
  };

  const mapOptions = {
    disableDefaultUI: true,
    // scrollwheel: false,
  };

  const isMounted = React.useRef(true);

  React.useEffect(
    () => () => {
      isMounted.current = false;
    },
    [],
  );

  React.useEffect(() => {
    setLocation(history.location.pathname);
  }, []);

  React.useEffect(() => {
    if (timeFilter === 'Live') {
      setVehicleInstruction(true);
    }
  }, [timeFilter]);

  /* eslint-disable */
  const decodePolyline = (encoded: any) => {
    // function for decoding polyline
    if (!encoded) return [];
    const poly = [];
    let index = 0;
    const len = encoded.length;
    let lat = 0;
    let lng = 0;

    while (index < len) {
      let b;
      let shift = 0;
      let result = 0;

      do {
        b = encoded.charCodeAt(index++) - 63;
        result = result | ((b & 0x1f) << shift);
        shift += 5;
      } while (b >= 0x20);

      let dlat = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
      lat += dlat;

      shift = 0;
      result = 0;

      do {
        b = encoded.charCodeAt(index++) - 63;
        result = result | ((b & 0x1f) << shift);
        shift += 5;
      } while (b >= 0x20);

      let dlng = (result & 1) != 0 ? ~(result >> 1) : result >> 1;
      lng += dlng;

      let p = {
        lat: lat / 1e5,
        lng: lng / 1e5,
      };
      poly.push(p);
    }
    return poly;
  };

  React.useEffect(() => {
    setLevel({ level: 4 });
  }, []);

  React.useEffect(() => {
    setIsLoading(true);
    setRegionRoutes({ region: true });
    const urls = match.url.split('/');
    const endFacilityId = urls[urls.length - 1];
    let dataUrl = '';
    if (timeFilter === 'Live') {
      dataUrl = `/api/routes/?end_facility_id=${endFacilityId}&live=true`;
    } else if (
      timeFilter === 'All program' &&
      typeof timeFilter === 'string' &&
      !timeFilter.includes('Last')
    ) {
      dataUrl = `/api/routes/?end_facility_id=${endFacilityId}`;
    } else {
      dataUrl = `/api/routes/?end_facility_id=${endFacilityId}&start_time=${timeFilter}`;
      if (typeof timeFilter === 'string' && timeFilter.includes('Last')) {
        dataUrl = `/api/routes/?end_facility_id=${endFacilityId}&start_time=${getTime(
          timeFilter,
        )}`;
      }
    }
    axios
      .get(dataUrl, config)
      .then((response: any) => {
        if (isMounted.current) {
          if (response.data.length < 1) {
            if (timeFilter === 'Live') {
              // setAlertText('No Live Case Found');
              // setAlert(true);
              gotoLiveCasesLocation(history.location.pathname.slice(5));
            } else {
              setAlertText('No Cases Found');
              setAlert(true);
            }
          }
          setNumOfJourneysByFacility(response.data.length);
          let routeDirectionsMap = {} as any;
          let uniqueRouteIds = [] as any;
          let journeyLimit = 100;
          let reducedJourneys = [...response.data] as any;
          if (response.data.length > journeyLimit) {
            setAlertText(
              `Too many journeys. Fetching most recent ${journeyLimit} out of ${response.data.length}`,
            );
            setAlert(true);
            reducedJourneys = response.data.slice(0, journeyLimit);
          }
          for (let i = 0; i < reducedJourneys.length; i++) {
            if (reducedJourneys[i].directions === null) {
              if (
                typeof routeDirectionsMap[reducedJourneys[i].id] === 'undefined'
              ) {
                routeDirectionsMap[reducedJourneys[i].id] = null;
                uniqueRouteIds.push(reducedJourneys[i].id);
              }
            }
          }
          // let routeLimit = 30;
          // if (uniqueRouteIds.length > routeLimit) {
          //   setAlertText(`Too many routes. Fetching only first ${routeLimit}`);
          //   setAlert(true);
          //   uniqueRouteIds = uniqueRouteIds.slice(0, routeLimit);
          // }
          const directions = uniqueRouteIds.map(
            async (routeId: any, index: any) =>
              new Promise((resolve, reject) => {
                let coordinates = routeId.split('_');
                if (coordinates.length < 2) {
                  reject('Invalid start/end lat long.');
                }
                axios
                  .get(
                    `/api/map/get_directions/?start=${coordinates[0]}&end=${coordinates[1]}`,
                    config,
                  )
                  .then((res: any) => {
                    let temp = res.data;
                    routeDirectionsMap[routeId] = temp;
                    resolve(temp);
                  })
                  .catch((err: any) => {
                    reject(err);
                  });
              }),
          );

          // console.log(response.data);
          // const routes = response.data.map(
          //   async (route: any, index: any) =>
          //     new Promise((resolve, reject) => {
          //       if (route.directions === null) {
          //         axios
          //           .get(`/api/routes/${route.id}/get_directions/`)
          //           .then((res: any) => {
          //             let temp = res.data;
          //             response.data[index].newroute = temp;
          //             resolve(response.data[index]);
          //           })
          //           .catch((err: any) => {
          //             reject(err);
          //           });
          //       } else {
          //         resolve(response.data[index]);
          //       }
          //     }),
          // );
          Promise.allSettled(directions)
            .then((res: any) => {
              if (isMounted.current) {
                let missingRoutesCount = 0;
                const newRes = reducedJourneys.map((route: any, index: any) => {
                  // route = route.value;
                  if (
                    route.directions === null &&
                    routeDirectionsMap[route.id]
                  ) {
                    route.directions = routeDirectionsMap[route.id];
                  }
                  setIsLoading(false);
                  if (
                    route.start_type === 'VILLAGE' &&
                    route.end_type === 'HEALTHCARE_FACILITY'
                  ) {
                    route.marker = {
                      start: {
                        lat: route.start_village_location_lat,
                        lng: route.start_village_location_lon,
                      },
                      end: {
                        lat: route.end_facility_location_lat,
                        lng: route.end_facility_location_lon,
                      },
                      type: 'village_to_facility',
                    };
                    route.markers = [
                      {
                        type: 'marker',
                        position: route.marker.start,
                        icon:
                          timeFilter === 'Live'
                            ? '/assets/icons/village_marker.svg'
                            : '/assets/icons/village_marker_historical.svg',
                      },
                      {
                        type: 'hospital',
                        icon: '/assets/icons/healthfacility_marker.svg',
                        position: route.marker.end,
                      },
                    ];
                  } else {
                    route.marker = {
                      start: {
                        lat: route.start_facility_location_lat,
                        lng: route.start_facility_location_lon,
                      },
                      end: {
                        lat: route.end_facility_location_lat,
                        lng: route.end_facility_location_lon,
                      },
                      type: 'facility_to_facility',
                    };
                    route.markers = [
                      {
                        type: 'hospital_marker',
                        position: route.marker.start,
                        icon: '/assets/icons/healthfacility_marker.svg',
                      },
                      {
                        type: 'hospital',
                        position: route.marker.end,
                        icon: '/assets/icons/healthfacility_marker.svg',
                      },
                    ];
                  }

                  let polyline;
                  // if (route?.newroute && route?.newroute?.routes.length > 0) {
                  //   polyline = decodePolyline(
                  //     route.newroute.routes[0].overview_polyline.points,
                  //   );
                  // } else
                  if (
                    route?.directions &&
                    route?.directions.routes.length > 0
                  ) {
                    polyline = decodePolyline(
                      route.directions.routes[0].overview_polyline.points,
                    );
                    route.options = {
                      strokeColor:
                        timeFilter === 'Live' ? '#E60000' : '#003993',
                      strokeOpacity: 10,
                      strokeWeight: 5,
                      fillColor: '#003993',
                      fillOpacity: 10,
                      visible: true,
                      radius: 30000,
                      zIndex: 1,
                      path: polyline,
                    };
                    route.polyline = polyline;
                  } else {
                    missingRoutesCount++;
                    route.options = null;
                    route.polyline = null;
                  }
                  // setCenterLocation(polyline[polyline.length - 1]);
                  return route;
                });
                const finalRes = newRes.filter((f: any) => f !== null);
                if (timeFilter === 'Live') {
                  axios
                    .get('/api/vehicle_types', config)
                    .then((vehicleRes: any) => {
                      setvehicleTypes(vehicleRes.data);
                    })
                    .catch((err: any) => {
                      console.log(err);
                    });
                  if (finalRes.length > 0) {
                    if (missingRoutesCount > 0) {
                      setAlertText(
                        "Couldn't fetch " + missingRoutesCount + ' route(s)',
                      );
                      setAlert(true);
                    }
                  } else {
                    setAlertText("Couldn't fetch routes");
                    setAlert(true);
                  }
                  const journeys = finalRes.map(
                    async (journey: any, index: any) =>
                      new Promise((resolve, reject) =>
                        axios
                          .get(`/api/journeys/${journey.journey_id}/`, config)
                          .then((res: any) => {
                            finalRes[index].live = res.data;
                            resolve(finalRes[index]);
                          })
                          .catch((err: any) => {
                            reject(err);
                          }),
                      ),
                  );
                  Promise.all(journeys)
                    .then((res: any) => {
                      const filteredJourneys = res.filter(
                        (journey: any) =>
                          journey.journey_status === 'AWAITING_DROP_OFF' ||
                          journey.journey_status === 'AWAITING_PICKUP',
                      ); // will only display the routes that are awaiting drop off and needs driver
                      setMapData(filteredJourneys);
                    })
                    .catch((err: any) => {
                      setIsLoading(false);
                      console.log(err);
                    });
                } else {
                  if (finalRes.length > 0) {
                    if (missingRoutesCount > 0) {
                      setAlertText(
                        "Couldn't fetch " + missingRoutesCount + ' routes',
                      );
                      setAlert(true);
                    }
                    setMapData(finalRes);
                  } else {
                    setAlertText("Couldn't fetch routes");
                    setAlert(true);
                  }
                }
              }
            })
            .catch((err: any) => {
              if (err.response.status === 403) redirectToLandingOnError();
              console.log(err);
              setAlertText(
                "Couldn't fetch routes, Please choose another facility",
              );
              setAlert(true);
              setIsLoading(false);
              // history.goBack();
            });
        }
      })
      .catch((e: any) => {
        setIsLoading(false);
        if (e.response.status === 403) redirectToLandingOnError();
        history.push('/nopagefound');
        console.log(e);
      });
  }, [timeFilter]);

  React.useEffect(() => {
    // this effect hook is used for getting the first item details
    if (mapData.length > 0 && timeFilter === 'Live') {
      let tempData = mapData.map((journey: any) => {
        // if (journey.journey_status === 'AWAITING_DROP_OFF') {
        //   return journey;
        // } else {
        //   return null;
        // }
        return journey; // returning all journey_status(only related to live cases)
      });
      tempData = tempData.filter((journey: any) => journey !== null);
      setSelectedVehicle(tempData[0].journey_id);
      setLiveDetailsState(tempData[0]);
    }
  }, [mapData, timeFilter]);

  const vehicleMarkers = () => {
    // this function is used for getting the vehicle markers
    const tempMapData = mapData;
    if (tempMapData?.length > 0) {
      const markerVehicle = [] as any;
      tempMapData.forEach((item: any) => {
        // skip the journey if item?.polyline is not available
        if (!item?.polyline) {
          return;
        }

        const timeToComplete = item?.live?.duration_seconds;
        const unixTime = item?.live?.configured_at_unix;
        const created = moment.unix(unixTime).format('llll'); // this is used for getting the time
        const duration = timeToComplete / 60; // time to complete in minutes
        const estimatedTimeToFinish = moment(created)
          .add(duration, 'minutes')
          .format('llll');
        const currentTime = moment().format('llll');

        let timeDifference = moment(estimatedTimeToFinish).diff(
          moment(currentTime),
          'minutes',
        );

        if (timeDifference < 0) {
          timeDifference = 0;
        }

        const polyline = item?.polyline;
        const polylineLength = polyline.length;
        if (item.journey_status === 'AWAITING_PICKUP') {
          markerVehicle.push({
            position: polyline[0],
            data: item,
            id: item.journey_id,
          });
        } else {
          const percentage = (timeDifference / duration) * 100; // this is used for calculating the percentage of journey completed
          const percentageLength = (polylineLength / 100) * (100 - percentage); // point where we need to add marker
          if (percentageLength > 0) {
            markerVehicle.push({
              position: polyline[Math.round(percentageLength - 1)],
              data: item,
              id: item.journey_id,
            });
          }
        }
      });
      setVehiclesMarker(markerVehicle);
    } else {
      return null;
    }
  };

  React.useEffect(() => {
    if (vehiclesMarker.length > 0) {
      let multiColorData = [] as any;
      vehiclesMarker.forEach((marker: any) => {
        const { position, data } = marker;
        const { journey_id } = data;
        multiColorData.push({
          position,
          id: journey_id,
        });
        setMulticolorRoute(multiColorData);
      });
    }
  }, [vehiclesMarker]);

  React.useEffect(() => {
    if (
      timeFilter === 'Live' &&
      mapDataFinal?.length > 0 &&
      multicolorRoute?.length > 0 &&
      vehiclesMarker?.length > 0
    ) {
      multicolorRoute.forEach((route: any) => {
        const tempMapData = mapDataFinal.filter(
          (journey: any) => journey.journey_id === route.id,
        );
        if (tempMapData[0]?.polyline?.length > 0) {
          const tempPolyline = tempMapData[0]?.polyline;
          const index = tempPolyline.findIndex(
            (polyline: any) => polyline.lat === route.position.lat,
          );
          const firstPart = tempPolyline.slice(0, index);
          setLines((old: any) => {
            return [
              ...old,
              {
                id: route.id,
                options: {
                  strokeColor: '#999797',
                  strokeOpacity: 10,
                  strokeWeight: 5,
                  fillColor: '#003993',
                  fillOpacity: 10,
                  visible: true,
                  radius: 30000,
                  zIndex: 4,
                  path: firstPart,
                },
              },
            ];
          });
        }
      });
    }
  }, [multicolorRoute, mapDataFinal, timeFilter]);

  React.useEffect(() => {
    if ((timeFilter === 'Live' && lines.length > 0) || timeFilter !== 'Live') {
      setLines([]);
    }
  }, [timeFilter]);

  React.useEffect(() => {
    if (timeFilter !== 'Live') {
      setMapData([]);
    }
  }, [timeFilter]);

  React.useEffect(() => {
    let time: any;
    if (timeFilter === 'Live') {
      time = setInterval(() => {
        vehicleMarkers();
      }, 3000);
    }
    return () => {
      clearInterval(time);
    };
  }, [mapData, timeFilter]);

  React.useEffect(() => {
    let time: any;
    if (mapData.length > 0 && googleMap !== null) {
      // const tempData1 = mapData;
      // const unique = [] as any;
      // const distinct = [];
      // for (let i = 0; i < tempData1.length; i++) {
      //   if (!unique[JSON.stringify(tempData1[i].marker)]) {
      //     distinct.push(tempData1[i]);
      //     unique[JSON.stringify(tempData1[i].marker)] = 1;
      //   }
      // }
      // let tempData = distinct;

      // set zoom level to fit all markers
      const latlngbounds = new google.maps.LatLngBounds();
      for (let i = 0; i < mapData.length; i++) {
        latlngbounds.extend(
          new google.maps.LatLng(
            mapData[i].marker.start.lat,
            mapData[i].marker.start.lng,
          ),
        );
        latlngbounds.extend(
          new google.maps.LatLng(
            mapData[i].marker.end.lat,
            mapData[i].marker.end.lng,
          ),
        );
      }
      googleMap.fitBounds(latlngbounds);
      setZooming(googleMap.zoom - 1);

      const tempData = mapData;
      let interval = 600;
      const limit = 10;
      let i = 0;
      const markerCountMap = {} as any;
      const remainingMarkers = [] as any;
      setMapDataFinal([]);
      (function loopIt(i: any) {
        if (markerCountMap[tempData[i].id]) {
          markerCountMap[tempData[i].id]['count']++;
        } else {
          markerCountMap[tempData[i].id] = { count: 1 };
        }
        tempData[i].marker.start.label = {
          text: `${markerCountMap[tempData[i].id]['count']}`,
          color:
            tempData[i].marker.type === 'facility_to_facility' ? 'red' : '#fff',
          className:
            tempData[i].marker.type === 'facility_to_facility'
              ? 'hospital-label'
              : 'marker-label',
        };
        if (timeFilter === 'Live') {
          tempData[i].marker.start.icon =
            tempData[i].marker.type === 'facility_to_facility'
              ? '/assets/icons/healthfacility_marker.svg'
              : '/assets/icons/village_marker.svg';
        } else {
          tempData[i].marker.start.icon =
            tempData[i].marker.type === 'facility_to_facility'
              ? '/assets/icons/healthfacility_marker.svg'
              : '/assets/icons/village_marker_historical.svg';
        }
        tempData[i].marker.end.label = {
          text: '',
          color: 'red',
          className: 'hospital-label',
        };
        tempData[i].marker.end.icon = '/assets/icons/hospital_marker.svg';
        if (i > limit) {
          interval = 0;
        }
        time = setTimeout(function () {
          if (interval !== 0 && i !== tempData.length - 1) {
            setMapDataFinal((old: any) => [...old, tempData[i]]);
          } else {
            remainingMarkers.push(tempData[i]);
          }
          if (i === tempData.length - 1) {
            setMapDataFinal((old: any) => [...old, ...remainingMarkers]);
          }
          if (i < tempData.length - 1) loopIt(i + 1);
        }, interval);
      })(i);
    }
    return () => {
      clearTimeout(time);
    };
  }, [mapData, googleMap]);

  React.useEffect(() => {
    // This is used to fix marker label overlapping
    if (mapData.length !== 0 && mapDataFinal.length === mapData.length) {
      // added set timeout to add delay for animation
      setTimeout(() => {
        const tempData = mapDataFinal.reverse();
        if (timeFilter === 'Live') {
          /* 
            For live cases only keeping label for top markers
            as there might be multiple journeys going on.
          */
          const markerMap = {} as any;
          for (let i = 0; i < tempData.length; i++) {
            if (markerMap[tempData[i].id]) {
              delete tempData[i].marker.start.label;
            } else {
              markerMap[tempData[i].id] = 1;
            }
          }
          setMapDataFinal(tempData);
        } else {
          /* 
            Keeping only top markers to imporve performance
            and reduce overlapping.
          */
          let topMarkers = [];
          const markerMap = {} as any;
          for (let i = 0; i < tempData.length; i++) {
            if (!markerMap[tempData[i].id]) {
              topMarkers.push(tempData[i]);
              markerMap[tempData[i].id] = 1;
            }
          }
          setMapDataFinal(topMarkers.reverse());
        }
      }, 1000);
    }
  }, [mapDataFinal]);

  const setMap = (map: any) => {
    setGoogleMap(map);
  };

  const vehicleIcon = {
    url: '/assets/icons/ambulance_dark.svg',
    anchor: googleMap !== null ? new google.maps.Point(30, 30) : null,
  };

  const faded = {
    url: '/assets/icons/ambulance_fade.svg',
    anchor: googleMap !== null ? new google.maps.Point(30, 30) : null,
  };

  const [selectedVehicle, setSelectedVehicle] = React.useState('' as any);

  const getIcon = (vehicle: any) => {
    const currentCountryId = window.location.pathname.split('/')[2];

    const vehicleTypeId =
      vehicle.data.live.driver_vehicle_type ||
      vehicle.data.live.ambulance_vehicle_type;
    const vehicleType = vehicleTypes.filter((vehicleType: any) => {
      return (
        vehicleType.country === currentCountryId &&
        vehicleType.id === vehicleTypeId &&
        vehicleType.image
      );
    });

    if (vehicleType.length > 0) {
      const selectedIcon = {
        anchor: googleMap !== null ? new google.maps.Point(30, 30) : null,
        scaledSize: new google.maps.Size(30, 30),
        // faded icon
        url: vehicleType[0].image_faded,
      };
      if (selectedVehicle === vehicle.id) {
        selectedIcon.url = vehicleType[0].image;
      } else {
        // faded icon
        selectedIcon.url = vehicleType[0].image_faded;
      }
      return selectedIcon;
    } else {
      return selectedVehicle === vehicle.id ? vehicleIcon : faded;
    }
  };

  // const markerClickHandlerHistorical = (latlon: any) => {
  //   const result = mapDataFinal.filter((data: any) => {
  //     return data.marker.start.lat === latlon.lat && data.marker.start.lng === latlon.lng
  //   });
  // };

  return (
    <>
      <MapContainer data-cy="facility_map">
        {isLoading ? <Loader /> : <></>}
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            // center={centerLocation}
            // zoom={13}
            zoom={zooming}
            onLoad={setMap}
            mapContainerStyle={mapStyles}
            options={mapOptions}
          >
            {/* <InfoWindow position={centerLocation}>
                <p>hi</p>
            </InfoWindow> */}
            {mapDataFinal?.map((data: any, index: any) => (
              <div key={index + 'i'}>
                <Polyline options={data?.options} />
                <Marker
                  position={{
                    lat: data.marker.start.lat,
                    lng: data.marker.start.lng,
                  }}
                  animation={2}
                  icon={data?.marker?.start?.icon}
                  label={data?.marker?.start?.label}
                  // onClick={()=>{markerClickHandlerHistorical(data.marker.start)}}
                />
                <Marker
                  position={{
                    lat: data.marker.end.lat,
                    lng: data.marker.end.lng,
                  }}
                  icon={data?.marker?.end?.icon}
                />
              </div>
            ))}
            {timeFilter === 'Live' &&
              mapData.length === mapDataFinal.length &&
              vehiclesMarker?.map((data: any, index: any) => (
                <Marker
                  onClick={() => {
                    setLiveDetailsState(data?.data);
                    setSelectedVehicle(data.id);
                  }}
                  key={`${index}k`}
                  icon={getIcon(data)}
                  position={data?.position}
                />
              ))}
            {timeFilter === 'Live' &&
              mapData.length === mapDataFinal.length &&
              lines?.map((data: any, index: any) => (
                <Polyline options={data.options} key={index} />
              ))}
          </GoogleMap>
        </LoadScript>
      </MapContainer>
    </>
  );
};
